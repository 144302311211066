import React from "react";

// components

import MapExample from "components/Maps/MapExample.js";

export default function Maps() {
  return (
    <>
      <div className="flex flex-wrap">
        <div className="w-full px-4">
          <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
            <iframe
              title="Vehicle Location"
              width="850"
              height="450"
              frameBorder={0}
              style={{
                border: 0,
              }}
              referrerPolicy="no-referrer-when-downgrade"
              src={`https://www.google.com/maps/embed/v1/place?key=${"AIzaSyChqGvtWu21VjE9cHFna0CCfuscVfLZYzQ"}&q=Ascend+Motors,San+Francisco`}
              allowFullScreen
            ></iframe>
          </div>
          <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
            <MapExample />
          </div>
        </div>
      </div>
    </>
  );
}
