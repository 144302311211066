import React from "react";

// components

import CardLineChart from "components/Cards/CardLineChart.js";
import CardBarChart from "components/Cards/CardBarChart.js";
import CardPageVisits from "components/Cards/CardPageVisits.js";
import CardSocialTraffic from "components/Cards/CardSocialTraffic.js";

export default function Dashboard() {
  return (
    <>
      <div className="relative flex flex-col min-w-0 break-words bg-white w-ful xl:w-4/12l mb-6 shadow-lg rounded text-center">
        <iframe
          title="Vehicle Location"
          label="Vehicle Location"
          width=""
          height="450"
          frameBorder={0}
          style={{
            border: 0,
          }}
          referrerPolicy="no-referrer-when-downgrade"
          src={`https://www.google.com/maps/embed/v1/place?key=${"AIzaSyChqGvtWu21VjE9cHFna0CCfuscVfLZYzQ"}&q=Ascend+Motors,San+Francisco`}
          allowFullScreen
        ></iframe>
      </div>
      <div className="flex flex-wrap">
        <div className="w-full xl:w-8/12 mb-12 xl:mb-0 px-4">
          <CardLineChart />
        </div>
        <div className="w-full xl:w-4/12 px-4">
          <CardBarChart />
        </div>
      </div>

      <div className="flex flex-wrap mt-4">
        <div className="w-full xl:w-8/12 mb-12 xl:mb-0 px-4">
          <CardPageVisits />
        </div>
        <div className="w-full xl:w-4/12 px-4">
          <CardSocialTraffic />
        </div>
      </div>
    </>
  );
}
